declare var $:any;
declare var pc_mode;

var index_mode = pc_mode;

$(function(){
    $(window).on("resize", function(){
        if(index_mode != pc_mode){
            initialize();
            setupLinkMenu();
            index_mode = pc_mode;
        }
    });
    initialize();
    setupLinkMenu();
});


function initialize(){
    if(pc_mode){
        $(".menu ul").show();
        $(".menu .news").show();
        $(".menu .haiku").show();
        $(".menu .more").show();
        $(".menu h3").show().next().hide();
        $("#medical h3").eq(0).addClass("open").next().show();
        $("#haidan h3").eq(0).addClass("open").next().show();
    }else{
        $(".menu .news").hide();
        $(".menu > ul").hide();
        $(".menu h3").removeClass("open").show().next().hide();
    }
    
//    $("#title h1").dblclick(function(e){
//        if(e.shiftKey){
//            if(e.ctrlKey){
//                if(confirm("準備中のコンテンツを今すぐデプロイしますか？")){
//                    location.href = "index.page?deploy";
//                }
//                return;
//            }
//            location.href = "index.page?preview";
//        }
//    });
}

function setupLinkMenu(){
    if(pc_mode){
        $(".menu h2").off('click');
    }else{
        $(".menu h2").off('click').click(function(elem){
            //let h3 = $(this).nextAll("h3");
            let h3 = $(this).nextAll("h3, .haiku, .more, .news");
            if(h3.length) {
                h3.removeClass("open").next().hide();
                h3.slideToggle();
            }else{
                $(this).next().slideToggle();
            }
        });
    }
    $(".menu h3").off('click').click(function(){
        let elem = $(this);
        if($(this).hasClass("open")){
            $(this).removeClass("open");
            elem.next().slideUp();
        }else{
            $(this).addClass("open");
            elem.next().slideDown();
        }
        if(elem.next().hasClass("loading")){
            elem.next().text("");
            $.get("index.page", {"medical":$(this).text()}, function(data){
                elem.next().replaceWith(data);
                elem.next().slideDown();
            });
        }
    });
}